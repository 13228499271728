* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  font-size: 16px;
  background-color: black;
  color: white;
}


.class-1 {
  line-height: 108%; 
  margin-bottom: 0in;
}

.class-2 {
  line-height: 108%; 
  text-indent: 0.49in; 
  margin-bottom: 0in;
}

.class-3 {
  line-height: 108%; 
  text-indent: 0.25in; 
  margin-bottom: 0in
}

.class-justify {
  text-align: justify;
}